exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/AboutUs.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-amazon-fba-seller-app-features-js": () => import("./../../../src/pages/Amazon_FBA_Seller_App_Features.js" /* webpackChunkName: "component---src-pages-amazon-fba-seller-app-features-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/Contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-contentful-blog-title-js": () => import("./../../../src/pages/{ContentfulBlog.title}.js" /* webpackChunkName: "component---src-pages-contentful-blog-title-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/Faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-features-actions-js": () => import("./../../../src/pages/features/Actions.js" /* webpackChunkName: "component---src-pages-features-actions-js" */),
  "component---src-pages-features-all-amazon-marketplaces-in-one-dashboard-js": () => import("./../../../src/pages/features/All_Amazon_Marketplaces_In_One_Dashboard.js" /* webpackChunkName: "component---src-pages-features-all-amazon-marketplaces-in-one-dashboard-js" */),
  "component---src-pages-features-asi-ns-performance-js": () => import("./../../../src/pages/features/ASINs_Performance.js" /* webpackChunkName: "component---src-pages-features-asi-ns-performance-js" */),
  "component---src-pages-features-fba-inbounds-js": () => import("./../../../src/pages/features/FBA_Inbounds.js" /* webpackChunkName: "component---src-pages-features-fba-inbounds-js" */),
  "component---src-pages-features-features-js": () => import("./../../../src/pages/features/Features.js" /* webpackChunkName: "component---src-pages-features-features-js" */),
  "component---src-pages-features-feedback-js": () => import("./../../../src/pages/features/Feedback.js" /* webpackChunkName: "component---src-pages-features-feedback-js" */),
  "component---src-pages-features-in-depth-sales-js": () => import("./../../../src/pages/features/In_Depth_Sales.js" /* webpackChunkName: "component---src-pages-features-in-depth-sales-js" */),
  "component---src-pages-features-inventory-management-js": () => import("./../../../src/pages/features/Inventory_Management.js" /* webpackChunkName: "component---src-pages-features-inventory-management-js" */),
  "component---src-pages-features-page-builder-js": () => import("./../../../src/pages/features/Page_Builder.js" /* webpackChunkName: "component---src-pages-features-page-builder-js" */),
  "component---src-pages-features-refund-alerts-and-customer-comments-js": () => import("./../../../src/pages/features/Refund_Alerts_and_Customer_Comments.js" /* webpackChunkName: "component---src-pages-features-refund-alerts-and-customer-comments-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-conditionsofuse-js": () => import("./../../../src/pages/legal/conditionsofuse.js" /* webpackChunkName: "component---src-pages-legal-conditionsofuse-js" */),
  "component---src-pages-legal-privacypolicy-js": () => import("./../../../src/pages/legal/privacypolicy.js" /* webpackChunkName: "component---src-pages-legal-privacypolicy-js" */),
  "component---src-pages-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-prices-js": () => import("./../../../src/pages/Prices.js" /* webpackChunkName: "component---src-pages-prices-js" */),
  "component---src-pages-road-map-js": () => import("./../../../src/pages/RoadMap.js" /* webpackChunkName: "component---src-pages-road-map-js" */),
  "component---src-templates-theblog-js": () => import("./../../../src/templates/theblog.js" /* webpackChunkName: "component---src-templates-theblog-js" */)
}

